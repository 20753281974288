import React from 'react';
import SEO from 'components/shared/SEO';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Icon from 'components/shared/Icon';
import arrowLeft from 'assets/icons/arrow-left.svg';
// import useLocation from 'hooks/useLocation';
// import CareerForm from '../components/CareerForm/CareerForm';

const Main = styled.main`
  padding-top: 80px;
  line-height: 1.5;
`;

const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    max-width: 1000px;
  }
  @media (max-width: 1080px) {
    padding: 0 clamp(2rem, 8vw, 80px);
  }
`;

const GoBackLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-top: 70px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: ${({ theme }) => theme.fontSize.lgm};
`;

const JobWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 32px auto 70px;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const JobContent = styled.div`
  /* max-width: calc(66.66% - 8px); */
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 20px;
  padding: 60px 45px 70px;
  background: rgb(255, 255, 255);
  @media (max-width: 1080px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 400px) {
    padding: 40px 20px 70px;
  }
`;

// const JobSidebar = styled.div`
//   max-width: calc(33.33% - 8px);
//   box-shadow: rgb(0 0 0 / 15%) 0px 3px 20px;
//   padding: 40px 40px 50px;
//   background: rgb(255, 255, 255);
//   @media (max-width: 1080px) {
//     max-width: 100%;
//   }
//   @media (max-width: 400px) {
//     padding: 40px 20px 50px;
//   }
// `;

const Header = styled.h1`
  width: 100%;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.lgm};
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 70px;
    background: rgb(112, 112, 112);
    margin: 25px 0 30px;
  }
`;

// const HeaderSmall = styled.h2`
//   width: 100%;
//   font-weight: ${({ theme }) => theme.bold};
//   font-size: ${({ theme }) => theme.fontSize.lg};
//   &:after {
//     content: '';
//     display: block;
//     height: 1px;
//     width: 70px;
//     background: rgb(112, 112, 112);
//     margin: 25px 0 0;
//   }
// `;

const Description = styled.div`
  &:last-child {
    &:after {
      content: '';
      display: block;
      margin: 50px auto 0px;
      width: 65px;
      height: 1px;
      background: rgb(0, 0, 0);
    }
  }
`;

const ListsWrapper = styled.div`
  margin: 40px 0;
`;

const ListLabel = styled.p`
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.m};
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  list-style-type: disc;
  list-style-position: inside;
`;

const ListItem = styled.li``;

// const JobLocation = styled.p`
//   padding: 40px 0 30px;
// `;

// const SidebarDescription = styled.p`
//   padding-bottom: 45px;
// `;

const CareerPage = ({ pageContext: { jobOffer } }) => {
  // const location = useLocation();

  // let applicationFormLang = 'pl';
  // let applicationTerm =
  //   'Działając na podstawie art. 6 ust. 1 pkt. a) Ogólnego Rozporządzenia o Ochronie Danych (RODO), wyrażam dobrowolnie zgodę na przetwarzanie moich danych osobowych przez Aseti Sp. z o.o. z siedzibą w Warszawie do celów związanych z procesem rekrutacji. Potwierdzam, że zapoznałem się z Polityką prywatności Aseti Sp. z o.o. opublikowaną na stronie https://aseti.pl/polityka-prywatnosci i jestem świadom wszystkich kwestii uregulowanych w powyższej Polityce prywatności związanych z przetwarzaniem moich danych osobowych i praw mi przysługujących, w tym: okresu, przez jaki przechowywane będą moje dane osobowe, prawa dostępu do danych, ich sprostowania, żądania usunięcia lub ograniczenia przetwarzania, prawa do wniesienia sprzeciwu wobec przetwarzania a także prawa do przenoszenia danych. Przysługuje mi też prawo do złożenia skargi do organu nadzorczego.';

  // let applicationFormDisclaimer =
  //   'Administratorem danych osobowych jest Aseti Sp. z o.o. z siedzibą przy ulicy Marynarskiej 21 w Warszawie. Przysługuje Państwu prawo dostępu do swoich danych osobowych oraz prawo do ich usunięcia, poprawiania, ograniczenia przetwarzania oraz cofnięcia zgody na ich przetwarzanie. Podanie danych osobowych jest dobrowolne, wysyłając formularz wyrażają Państwo zgodę na ich przetwarzanie.';

  // if (location.includes('/en')) {
  //   applicationFormLang = 'en';
  //   /*
  //   translation for applicationTerm
  //   */
  //   applicationFormDisclaimer =
  //     'The administrator of the personal data is Aseti Sp. z o.o. with its registered office at st. Marynarska 21 in Warsaw. You have the right to access your personal data and the right to delete, correct, limit processing and withdraw your consent to processing of your personal data. Providing personal data is voluntary, by sending the form you consent to its processing.';
  // }

  // if (location.includes('/de')) {
  //   applicationFormLang = 'de';
  //   /*
  //   translation for applicationTerm
  //   */
  //   applicationFormDisclaimer =
  //     'Der Verwalter der persönlichen Daten ist Aseti Sp. z o.o. mit Sitz in der Marynarska 21 Strasse in Warschau. Sie haben das Recht auf Zugang zu Ihren personenbezogenen Daten sowie das Recht auf Löschung, Berichtigung, Einschränkung der Verarbeitung und Widerruf der Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten. Die Angabe personenbezogener Daten ist freiwillig, mit dem Absenden des Formulars erklären Sie sich mit deren Verarbeitung einverstanden.';
  // }

  const displayResponsibilities =
    jobOffer.jobOfferResponsibilities?.length > 0 &&
    jobOffer.jobOfferResponsibilities?.[0].jobOfferResponsibilitesSingle !==
      null;

  const displayRequirements =
    jobOffer.jobOfferRequirements.length > 0 &&
    jobOffer.jobOfferRequirements?.[0].jobOfferRequirementsSingle !== null;

  const displayNiceToSee =
    jobOffer.jobOfferNicetosee?.length > 0 &&
    jobOffer.jobOfferNicetosee?.[0].jobOfferNicetoseeSingle !== null;

  const displayWeOffer =
    jobOffer.jobOfferOuroffer?.length > 0 &&
    jobOffer.jobOfferOuroffer?.[0].jobOfferOurofferSingle !== null;

  return (
    <Main>
      <SEO title="Kariera" />
      <Wrapper>
        <GoBackLinkWrapper>
          <Icon size={35} src={arrowLeft} alt="go back" />
          <StyledLink to="/">Powrót</StyledLink>
        </GoBackLinkWrapper>
        <JobWrapper>
          <JobContent>
            <Header
              dangerouslySetInnerHTML={{ __html: jobOffer.jobOfferPosition }}
            />
            <Description
              dangerouslySetInnerHTML={{ __html: jobOffer.jobOfferIntro }}
            />
            <ListsWrapper>
              {displayResponsibilities && (
                <>
                  <ListLabel> Czym będziesz się zajmować: </ListLabel>
                  <List>
                    {jobOffer.jobOfferResponsibilities.map(
                      item =>
                        item.jobOfferResponsibilitesSingle && (
                          <ListItem key={item.jobOfferResponsibilitesSingle}>
                            {item.jobOfferResponsibilitesSingle}
                          </ListItem>
                        )
                    )}
                  </List>
                </>
              )}
              {displayRequirements && (
                <>
                  <ListLabel> Nasze wymagania wobec Ciebie: </ListLabel>
                  <List>
                    {jobOffer.jobOfferRequirements.map(
                      item =>
                        item.jobOfferRequirementsSingle && (
                          <ListItem key={item.jobOfferRequirementsSingle}>
                            {item.jobOfferRequirementsSingle}
                          </ListItem>
                        )
                    )}
                  </List>
                </>
              )}

              {displayNiceToSee && (
                <>
                  <ListLabel> Mile widziane: </ListLabel>
                  <List>
                    {jobOffer.jobOfferNicetosee.map(
                      item =>
                        item.jobOfferNicetosee && (
                          <ListItem key={item.jobOfferNicetoseeSingle}>
                            {item.jobOfferNicetoseeSingle}
                          </ListItem>
                        )
                    )}
                  </List>
                </>
              )}

              {displayWeOffer && (
                <>
                  <ListLabel> Oferujemy: </ListLabel>
                  <List>
                    {jobOffer.jobOfferOuroffer.map(
                      item =>
                        item.jobOfferOurofferSingle && (
                          <ListItem key={item.jobOfferOurofferSingle}>
                            {item.jobOfferOurofferSingle}
                          </ListItem>
                        )
                    )}
                  </List>
                </>
              )}
            </ListsWrapper>
            <Description
              dangerouslySetInnerHTML={{ __html: jobOffer.jobOfferEnding }}
            />
          </JobContent>
          {/* <JobSidebar>
            <HeaderSmall>Job title</HeaderSmall>
            <JobLocation>Location</JobLocation>
            <SidebarDescription>
              Dołącz do naszego zespołu! Wypełnij krótki formularz i dołącz
              swoje CV. Jeśli jesteś właściwym kandydatem, skontatkujemy się z
              tobą i ustalimy termin spotkania.
            </SidebarDescription>
            <CareerForm
              language={applicationFormLang}
              term={applicationTerm}
              info={applicationFormDisclaimer}
            />
          </JobSidebar> */}
        </JobWrapper>
      </Wrapper>
    </Main>
  );
};

export default CareerPage;
